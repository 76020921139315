import { useState } from 'react';
import { useFeature } from 'bv-hooks';
import { useSelector } from 'react-redux';
import { ModalWindow, LiveChat } from 'bv-components';
import { t } from 'bv-i18n';
import { replaceFragment } from 'bv-helpers/location';
import classNames from 'classnames';
import TrustPilot from 'BVComponents/site_footer/components/trust_pilot';
import CashierHeaderMenu from '../components/cashier_header_menu';

export default ({ tKey, className, backUrl }) => (WrappedComponent) => {
  const WithCashierModal = (props) => {
    const [isAsiaCashier, featureResolved] = useFeature('asia_cashier');
    const [showMenu, setShowMenu] = useState(false);
    const trustPilotConfig = useSelector(
      (state) => state.siteFooter?.trustPilotConfig || { enabled: false },
    );

    if (!featureResolved) return null;

    return (
      <ModalWindow
        title={t(tKey)}
        className={classNames(`${isAsiaCashier ? 'asia-' : ''}cashier-modal`, className)}
        {...(backUrl ? { onBack: () => replaceFragment(backUrl) } : {})}
        scrollToTop
        dots
        onDotsClick={() => setShowMenu(true)}
      >
        <WrappedComponent {...props} />
        {trustPilotConfig?.enabled && (
          <TrustPilot {...trustPilotConfig} isCashier />
        )}

        <LiveChat className="live-chat" location="Cashier" />
        { showMenu && (
          <CashierHeaderMenu
            onCloseClick={() => setShowMenu(false)}
            isAsiaCashier={isAsiaCashier}
          />
        )}
      </ModalWindow>
    );
  };

  return WithCashierModal;
};
