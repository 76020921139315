import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import reducer from './redux/reducer';

import DepositPage from './components/deposit/deposit_redux';
import WithdrawPage from './components/withdraw/withdraw_redux';
import HelpPage from './components/help';

const { store } = window.reduxState;
const { addReducers } = window.reduxState;

addReducers({
  asiaCashier: reducer,
});

const AsiaCashierApp = () => (
  <Provider store={store}>
    <Switch>
      <Route path="/bv_cashier/:operation(deposit|withdraw)/help" component={HelpPage} exact />
      <Route path="/bv_cashier/deposit/(payment_method)?/:paymentMethodId?" component={DepositPage} />
      <Route path="/bv_cashier/withdraw/(payment_method)?/:paymentMethodId?" component={WithdrawPage} />
    </Switch>
  </Provider>
);

export default AsiaCashierApp;
