import { Card, DotsIcon } from 'bv-components';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import ReactSwipe from 'react-swipe';
import HelpSolutionsList from '../help_solutions_list/help_solutions_list_redux';

const HelpView = (props) => {
  const {
    currentSolutionHelp,
    currentStep,
    setCurrentStep,
    showSolutionsList,
    setShowSolutionsList,
  } = props;

  const listItemStyles = (index) => classnames('ac-help-modal-contents__step-list-item', {
    active: index <= currentStep,
  });

  const swipeOptions = {
    widthOfSiblingSlidePreview: 10,
    continuous: false,
    disableScroll: false,
    stopPropagation: false,
    startSlide: currentStep,
    callback: (index) => setCurrentStep(index),
  };

  return (
    <div className="ac-help-modal-contents">
      <div className="ac-help-modal-contents__payment-method" onClick={() => setShowSolutionsList(true)}>
        <span className="ac-help-modal-contents__payment-method-name">{t(`cashier.payments.payment_method.${currentSolutionHelp.solutionId}`)}</span>
        <DotsIcon type="dots-icon-ac-help-view" />
      </div>
      <Card className="ac-help-modal-contents__step-list">
        <p className="ac-help-modal-contents__title">{t('help_step')}</p>
        <div className={`ac-help-modal-contents__step-list-wrapper steps-${currentSolutionHelp.steps.length}`}>
          {currentSolutionHelp.steps.map((step, index) => (
            <span
              key={`step_${index + 1}`}
              className={listItemStyles(index)}
              onClick={() => setCurrentStep(index)}
            >
              {index + 1}
            </span>
          ))}
        </div>
      </Card>
      <div className="ac-help-modal-contents__cards-wrapper">
        <ReactSwipe swipeOptions={swipeOptions} childCount={currentSolutionHelp.steps.length} className="ac-help-modal-contents__swipe">
          {currentSolutionHelp.steps.map((step, index) => (
            <Card key={`help_step_${index + 1}`} className="ac-help-modal-contents__content">
              { (step.text.trim() && <p>{step.text}</p>) || <img src={step.image_url} alt={t('help_step_image')} />}
            </Card>
          ))}
        </ReactSwipe>
      </div>
      {showSolutionsList && <HelpSolutionsList onCloseClick={() => setShowSolutionsList(false)} />}
    </div>
  );
};

HelpView.propTypes = {
  currentSolutionHelp: PropTypes.instanceOf(Object).isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  showSolutionsList: PropTypes.bool.isRequired,
  setShowSolutionsList: PropTypes.func.isRequired,
};

export default HelpView;
