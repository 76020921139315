import {
  partial,
  isEmpty,
  compose,
  groupBy,
} from 'underscore';
import { t, locale } from 'bv-i18n';
import { originId } from 'bv-helpers/session';
import { getBlackBox } from 'bv-services';
import { defaultAssetPath } from 'bv';
import { DEPOSIT, WITHDRAW, shouldGroupMethods } from 'CashierCommon/helpers';
import { fetchDeposits as apiFetchDeposits } from 'CashierCommon/api/deposit_api';
import {
  createCashierSession,
  fetchPaymentSettings,
  fetchAvailableWallets,
} from 'CashierCommon/api/api';
import {
  fetchWithdrawals as apiFetchWithdrawals,
  fetchPendingWithdrawals,
} from 'CashierCommon/api/withdraw_api';
import {
  setPaymentMethods,
  setSelectedPaymentMethod,
  setOperation,
  setCashierSession,
  setPaymentSettings,
  setSelectedWalletId,
  setWalletsList,
  setPendingWithdraws,
} from './actions';
import {
  getPaymentMethods,
  getCurrentOperation,
  getWalletId,
  getSelectedPaymentMethod,
  getGenericMessages,
  getAllPaymentSolutions,
  getWalletsFetched,
} from './selectors';

const fetchPaymentMethodsFns = {
  [DEPOSIT]: apiFetchDeposits,
  [WITHDRAW]: apiFetchWithdrawals,
};

export const fetchSettings = () => (dispatch, getState) => {
  const state = getState();
  if (!isEmpty(getGenericMessages(state)) && !isEmpty(getAllPaymentSolutions(state))) return;

  fetchPaymentSettings()
    .then((response) => dispatch(setPaymentSettings(response)));
};

// group payment methods with same payment_solution_id (except if id = 0)
export const groupPaymentMethods = (paymentMethods) => {
  const groupedMethods = groupBy(paymentMethods, 'payment_solution_id');
  return paymentMethods
    .map((method) => ({
      ...method,
      groupedMethod: !!method.payment_solution_id
        && groupedMethods[method.payment_solution_id].length > 1,
    }))
    .filter((method, index, self) => !method.groupedMethod || index === self.findIndex((el) => (
      el.payment_solution_id === method.payment_solution_id
    )),
    );
};

const presentMethods = (methods, shouldGroup) => {
  const groupedMethods = shouldGroup ? groupPaymentMethods(methods) : methods;

  return groupedMethods.map((currentMethod) => {
    const logoUrl = (shouldGroup && currentMethod.payment_solution_id !== 0)
      ? currentMethod.paysolLogoUrl
      : currentMethod.logo_url;

    const tKey = currentMethod.payment_solution_id === 0
      ? currentMethod.name
      : currentMethod.payment_solution_id;

    return {
      ...currentMethod,
      displayName: t(`cashier.payments.payment_method.${tKey}`),
      logoUrl: defaultAssetPath(logoUrl),
    };
  });
};

export const fetchPaymentMethods = (operation) => (dispatch, getState) => {
  if (!isEmpty(getPaymentMethods(getState()))
    && getCurrentOperation(getState()) === operation) return;

  fetchPaymentMethodsFns[operation]()
    .then((response) => {
      const methods = presentMethods(response, shouldGroupMethods(operation));
      dispatch(setPaymentMethods(methods));
    });
};

// In  case we want to add more this avoid required changes on current imports of this function
export const setPaymentMethod = (method) => (dispatch) => {
  dispatch(setSelectedPaymentMethod(method));
};

export const fetchDeposits = partial(fetchPaymentMethods, DEPOSIT);

export const setCurrentOperation = (operation) => (dispatch) => {
  if (getCurrentOperation === operation) return;

  dispatch(setOperation(operation));
};

export const createPaymentSession = () => async (dispatch, getState) => {
  const state = getState();
  const walletId = getWalletId(state);
  const operation = getCurrentOperation(state);
  const selectedPaymentMethod = getSelectedPaymentMethod(state, { operation });

  if (!walletId || !selectedPaymentMethod || !selectedPaymentMethod.name) return;

  const params = {
    wallet_id: walletId,
    iobb: await getBlackBox(),
    operation,
    origin_id: originId(),
    method: selectedPaymentMethod.name,
    css_url: window.VCSTATE.CASHIER_CSS_URL,
    callback_url: `${window.VCSTATE.BASE_URL}/cashier/callback/${operation}/`,
    _method: 'put',
  };

  params.processing_payment_url = `${window.location.origin}/bv_cashier/${locale()}/processing_payment?mobile=1`;
  if (selectedPaymentMethod.groupedMethod && shouldGroupMethods(operation)) {
    params.solution_id = selectedPaymentMethod.payment_solution_id;
  }
  createCashierSession(params).then(compose(dispatch, setCashierSession));
};

export const fetchWallets = () => (dispatch, getState) => {
  if (getWalletsFetched(getState())) return;

  fetchAvailableWallets()
    .then((response) => {
      dispatch(setWalletsList(response));
      if (response.length) dispatch(setSelectedWalletId(response[0][1]));
    });
};

export const fetchWithdraws = partial(fetchPaymentMethods, WITHDRAW);

export const fetchPendingWithdraws = () => (dispatch) => (
  fetchPendingWithdrawals().then(compose(dispatch, setPendingWithdraws))
);
