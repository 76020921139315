import { createSelector } from 'reselect';
import {
  compose,
  find,
  property,
  uniq,
} from 'underscore';
import { defaultAssetPath } from 'bv';
import { t } from 'bv-i18n';

const getAsiaCashier = (state) => state.asiaCashier;

const getSettings = createSelector(
  getAsiaCashier,
  (substate) => substate.settings,
);

export const getPaymentMethods = createSelector(
  getAsiaCashier,
  (substate) => substate.paymentMethods,
);

export const getUserSolutions = createSelector(
  getSettings,
  getPaymentMethods,
  ({ paymentSolutions }, methods) => (
    uniq(methods.map((m) => paymentSolutions[m.payment_solution_id] && {
      ...paymentSolutions[m.payment_solution_id],
      name: t(`cashier.payments.payment_method.${m.payment_solution_id}`),
      logoUrl: defaultAssetPath(m.paysolLogoUrl),
    }), 'solutionId').filter(Boolean)
  ),
);

export const getSolutionsHelpList = createSelector(
  getUserSolutions,
  (solutions) => solutions.filter((solution) => solution.steps?.length),
);

export const getCurrentSolutionHelp = createSelector(
  getAsiaCashier,
  getSolutionsHelpList,
  ({ selectedSolutionHelp }, solutions) => find(solutions, { solutionId: selectedSolutionHelp })
    || solutions[0],
);

export const getCurrentSolutionHelpId = createSelector(
  getCurrentSolutionHelp,
  property('solutionId'),
);

export const getCurrentOperation = createSelector(
  getAsiaCashier,
  (substate) => substate.currentOperation,
);

export const getSelectedPaymentMethod = createSelector(
  getAsiaCashier,
  (substate) => substate.selectedPaymentMethod,
);

export const getSession = createSelector(
  getAsiaCashier,
  (substate) => substate.session,
);

export const getLoadingSession = compose(property('loadingSession'), getAsiaCashier);

export const getGenericMessages = compose(property('genericMessages'), getSettings);

export const getAllPaymentSolutions = compose(property('paymentSolutions'), getSettings);

export const getSelectedSolution = createSelector(
  getSettings,
  getSelectedPaymentMethod,
  (settings, method) => method && settings.paymentSolutions[method.payment_solution_id],
);

export const getGenericMessage = createSelector(
  getSettings,
  getCurrentOperation,
  (settings, operation) => (
    settings.genericMessages.find((msg) => msg.transaction_type.toLowerCase() === operation)
  ),
);

export const getPaymentMessage = createSelector(
  getSelectedSolution,
  getCurrentOperation,
  (solution, operation) => solution && solution.messages && solution.messages.find(
    (msg) => msg.transaction_type.toLowerCase() === operation,
  ),
);

export const getSettingsFetched = createSelector(
  getSettings,
  (settings) => settings.settingsFetched,
);

export const getWalletsFetched = createSelector(
  getAsiaCashier,
  (substate) => substate.walletsFetched,
);

export const getWalletId = createSelector(
  getAsiaCashier,
  (substate) => substate.selectedWalletId,
);

export const getAllWallets = createSelector(
  getAsiaCashier,
  (substate) => substate.wallets,
);

export const selectPendingWithDraws = createSelector(
  getAsiaCashier,
  (substate) => substate.pendingWithdraws,
);

export const selectPendingAmount = createSelector(
  selectPendingWithDraws,
  (pendingWithdraws) => pendingWithdraws.reduce(
    (sum, { amount }) => sum + parseFloat(amount), 0,
  ).toFixed(2),
);
