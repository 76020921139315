import { getJSON, deleteJSON } from 'bv-fetch';

export const fetchWithdrawals = () => (
  getJSON('/cashier/withdraw/payment_methods.json')
);

export const fetchPendingWithdrawals = () => (
  getJSON('/cashier/pending_withdrawals.json')
);

export const deleteWithdraw = (item) => (
  deleteJSON(
    `/cashier/pending_withdrawals/${item.transaction_id}.json`,
    {},
  )
);
