import { useEffect } from 'react';
import PropTypes from 'prop-types';
import iframeMessageReceiver from 'CashierCommon/hooks/iframe_message_receiver';
import { operationTypes } from 'CashierCommon/helpers';
import { useCashierForm } from 'SharedComponents/cashier/hooks';

import CashierFormView from './cashier_form_view';

const CashierFormContainer = ({
  session,
  selectedWalletId,
  selectedPaymentMethod,
  createCashierSession,
  fetchPendingWithdraws,
  operation,
  setLoadingPaymentMethod,
}) => {
  const { form, canLoadForm } = useCashierForm(session, operation, setLoadingPaymentMethod);

  useEffect(() => {
    if (selectedPaymentMethod && selectedPaymentMethod.name && !session) createCashierSession();
  }, [selectedWalletId, selectedPaymentMethod, session]);

  useEffect(iframeMessageReceiver({
    form,
    operation,
    createCashierSession,
    fetchPendingWithdraws,
  }), []);

  return (
    <CashierFormView
      form={form}
      canLoadForm={canLoadForm}
      session={session}
      setLoadingPaymentMethod={setLoadingPaymentMethod}
    />
  );
};

CashierFormContainer.defaultProps = {
  session: null,
};

CashierFormContainer.propTypes = {
  session: PropTypes.instanceOf(Object),
  selectedPaymentMethod: PropTypes.instanceOf(Object).isRequired,
  selectedWalletId: PropTypes.number.isRequired,
  createCashierSession: PropTypes.func.isRequired,
  fetchPendingWithdraws: PropTypes.func.isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
  setLoadingPaymentMethod: PropTypes.func.isRequired,
};

export default CashierFormContainer;
