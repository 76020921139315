import { Link, Card } from 'bv-components';
import { t } from 'bv-i18n';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useRouteMatch } from 'react-router-dom';

const CashierMenuItem = ({
  name,
  logoUrl,
  id,
  isRecommended,
}) => {
  const { url } = useRouteMatch();

  return (
    <Link to={`${url}/payment_method/${id}`}>
      <Card className={classnames('asia-cashier__card', { 'is-recommended': isRecommended })}>

        {isRecommended && (
          <div className="asia-cashier__label">{t('javascript.cashier.recommended')}</div>
        )}

        <div className="asia-cashier__card-content">
          <div className="asia-cashier__logo">
            <img className="asia-cashier__img" src={logoUrl} alt={name} />
          </div>
          <span className="asia-cashier__title">{name}</span>
        </div>
      </Card>
    </Link>
  );
};

CashierMenuItem.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
  isRecommended: PropTypes.bool.isRequired,
};

export default CashierMenuItem;
