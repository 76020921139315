import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { setFragment } from 'bv-helpers/location';
import AcceptMethodChangeView from './accept_method_change_view';

const modalActions = (continueClick) => ([
  {
    label: t('cashier.payments.activation_alert.cancel'),
    reverse: true,
    onClick: () => setFragment('bv_cashier/overview'),
    id: 'cancel-button',
  },
  {
    label: t('cashier.payments.activation_alert.continue'),
    primary: true,
    onClick: () => continueClick(false),
    id: 'continue-button',
  },
]);

const AcceptMethodChangeContainer = ({ session }) => {
  const [show, toggleModal] = useState(false);

  useEffect(() => {
    if (session && session.error_code && session.error_code === 'PCC-200') {
      toggleModal(true);
    }
  }, [session]);

  return show && <AcceptMethodChangeView actions={modalActions(toggleModal)} />;
};

AcceptMethodChangeContainer.defaultProps = {
  session: null,
};

AcceptMethodChangeContainer.propTypes = {
  session: PropTypes.instanceOf(Object),
};

export default AcceptMethodChangeContainer;
