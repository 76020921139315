import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCurrentOperation, getPaymentMethods, getSelectedPaymentMethod } from 'AsiaCashier/redux/selectors';
import { setPaymentMethod } from 'AsiaCashier/redux/thunks';
import CashierMenuView from './cashier_menu_view';

const mapStateToProps = createStructuredSelector({
  methods: getPaymentMethods,
  operation: getCurrentOperation,
  selectedMethod: getSelectedPaymentMethod,
});

export default connect(mapStateToProps, { setPaymentMethod })(CashierMenuView);
