import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSession } from 'AsiaCashier/redux/selectors';
import KycMessageView from './kyc_message_view';

const mapStateToProps = createStructuredSelector({
  session: getSession,
});

export default connect(mapStateToProps)(KycMessageView);
