import { useParams } from 'react-router';
import { operationTypes } from 'CashierCommon/helpers';
import getHelpPageComponent from './help_redux';

const helpPages = operationTypes.reduce((pages, slug) => ({
  ...pages,
  [slug]: getHelpPageComponent(slug),
}), {});

const HelpPage = () => {
  const { operation } = useParams();
  const Component = helpPages[operation];

  return <Component />;
};

export default HelpPage;
