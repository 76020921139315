import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { operationTypes } from 'CashierCommon/helpers';
import PaymentMethodsList from './payment_methods_list';

const RECOMMENDED_METHODS = 3;

const CashierMenuView = ({
  methods, operation, selectedMethod, setPaymentMethod,
}) => {
  const history = useHistory();
  const paymentMethodId = parseInt(useParams().paymentMethodId, 10);

  useEffect(() => {
    if (methods.length === 1 && methods[0].id !== paymentMethodId) {
      history.replace(`/bv_cashier/${operation}/payment_method/${methods[0].id}`);
    } else if (paymentMethodId && methods.length) {
      const method = methods.find(({ id }) => paymentMethodId === id);
      if (method) {
        setPaymentMethod(method);
      } else {
        history.replace(`/bv_cashier/${operation}`);
      }
    } else if (selectedMethod) {
      setPaymentMethod(null);
    }
  }, [methods, operation, paymentMethodId]);

  return !selectedMethod && (
    <div className="asia-cashier__list">
      <div className="asia-cashier__list-main">
        <PaymentMethodsList methods={methods.slice(0, RECOMMENDED_METHODS)} isRecommended />
      </div>

      {methods.length > RECOMMENDED_METHODS && (
        <>
          <p>{t('javascript.cashier.more_payment_methods')}</p>

          <div className="asia-cashier__list-alt">
            <PaymentMethodsList methods={methods.slice(RECOMMENDED_METHODS)} />
          </div>
        </>
      )}
    </div>
  );
};

CashierMenuView.propTypes = {
  methods: PropTypes.arrayOf(Object).isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
  selectedMethod: PropTypes.instanceOf(Object),
  setPaymentMethod: PropTypes.func.isRequired,
};

CashierMenuView.defaultProps = {
  selectedMethod: null,
};

export default CashierMenuView;
