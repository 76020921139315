import { useSelector } from 'react-redux';
import { getPaymentMessage } from 'AsiaCashier/redux/selectors';

const PaymentSolutionMessage = () => {
  const paymentMessage = useSelector(getPaymentMessage);

  return !!paymentMessage && <p className="asia-cashier__header-msg">{paymentMessage.message}</p>;
};

export default PaymentSolutionMessage;
