import {
  SET_SETTINGS,
  SET_SELECTED_SOLUTION_HELP,
  SET_WALLET_LIST,
  SET_SELECTED_WALLET,
  SET_LOADING_SESSION,
  SET_CASHIER_SESSION,
  SET_METHODS_LIST,
  SET_PENDING_WITHDRAWS,
  SET_SELECTED_METHOD,
  SET_CURRENT_OPERATION,
} from './actions';

const initialState = {
  settings: {
    genericMessages: [],
    paymentSolutions: {},
    settingsFetched: false,
  },
  currentOperation: null,
  paymentMethods: [],
  selectedPaymentMethod: null,
  session: null,
  loadingSession: false,
  selectedSolutionHelp: null,
  wallets: [],
  selectedWalletId: 0,
  walletsFetched: false,
  pendingWithdraws: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        settings: {
          genericMessages: action.response.genericWarningMessages || [],
          paymentSolutions: action.response.paymentSolutions || {},
          settingsFetched: true,
        },
      };
    case SET_SELECTED_SOLUTION_HELP:
      return {
        ...state,
        selectedSolutionHelp: action.solutionId,
      };
    case SET_WALLET_LIST:
      return {
        ...state,
        walletsFetched: true,
        wallets: action.wallets,
      };
    case SET_SELECTED_WALLET:
      return {
        ...state,
        selectedWalletId: action.id,
      };
    case SET_CURRENT_OPERATION:
      return {
        ...initialState,
        settings: state.settings,
        wallets: state.wallets,
        selectedWalletId: state.selectedWalletId,
        walletsFetched: state.walletsFetched,
        currentOperation: action.operation,
      };
    case SET_LOADING_SESSION:
      return {
        ...state,
        loadingSession: action.loading,
      };
    case SET_CASHIER_SESSION:
      return {
        ...state,
        session: action.session,
        loading: action.session.success,
      };
    case SET_METHODS_LIST:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
      };
    case SET_SELECTED_METHOD: {
      return {
        ...state,
        loadingSession: !!action.paymentMethod,
        session: null,
        selectedPaymentMethod: action.paymentMethod,
        selectedSolutionHelp: action.paymentMethod?.payment_solution_id,
      };
    }
    case SET_PENDING_WITHDRAWS:
      return {
        ...state,
        pendingWithdraws: action.pendingWithdraws,
      };
    default:
      return state;
  }
};
