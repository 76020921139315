import PropTypes from 'prop-types';
import { Modal, RenderInBody } from 'bv-components';
import classNames from 'classnames';

const solutionStyles = (currentSolutionHelpId, solutionId) => classNames(
  'list-modal-item',
  'ac-help-list__item',
  { active: parseInt(currentSolutionHelpId, 10) === parseInt(solutionId, 10) },
);

const HelpSolutionsListView = ({
  onCloseClick, paymentSolutions, onSolutionClick, currentSolutionHelpId,
}) => (
  <RenderInBody>
    <Modal
      info
      iconDotsClosable
      icon={false}
      className="list-modal asia-cashier-list-modal"
      onCloseClick={onCloseClick}
    >
      <ul className="list-modal-items ac-help-list">
        {paymentSolutions.map((item) => (
          <li
            key={`solution_help_${item.solutionId}`}
            className={solutionStyles(currentSolutionHelpId, item.solutionId)}
            onClick={() => onSolutionClick(item)}
          >
            <span className="ac-help-list__image-wrapper">
              <img src={item.logoUrl} alt={item.name} />
            </span>
            <span className="ac-help-list__item--title">
              {item.name}
            </span>
          </li>
        ))}
      </ul>
    </Modal>
  </RenderInBody>
);

HelpSolutionsListView.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  paymentSolutions: PropTypes.arrayOf(Object).isRequired,
  currentSolutionHelpId: PropTypes.number.isRequired,
  onSolutionClick: PropTypes.func.isRequired,
};

export default HelpSolutionsListView;
