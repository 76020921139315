import { isEmpty } from 'underscore';
import {
  extractParamsFromUrl, DEPOSIT, WITHDRAW, handleCashierResponse,
} from 'CashierCommon/helpers';

export default ({
  form,
  operation,
  createCashierSession,
  fetchPendingWithdraws,
  fetchDepositLimit,
  fetchPayments,
  setPaymentMethods,
  setPaymentResult,
}) => () => {
  const catchIframeMessage = (e) => {
    const { data: { source } } = e;
    if (source === 'transactionResult') {
      handleCashierResponse(e, fetchDepositLimit, setPaymentResult);
      createCashierSession(operation);

      if (operation === WITHDRAW) {
        fetchPendingWithdraws();
      } else if (operation === DEPOSIT && fetchPayments) {
        fetchPayments();
      }
    }

    // Iframe resize
    if (!isEmpty(e.data) && e.data[0] === 'setHeight' && form && form.current) {
      // eslint-disable-next-line no-param-reassign
      form.current.parentElement.style.height = `${e.data[1]}px`;
      form.current.parentElement.classList.add('is-heightset');
    }
  };

  window.addEventListener('message', catchIframeMessage, false);

  const paramsAfterRedirect = extractParamsFromUrl();
  if (paramsAfterRedirect) {
    catchIframeMessage(paramsAfterRedirect);
  }

  return () => {
    window.removeEventListener('message', catchIframeMessage, false);
    if (setPaymentResult) setPaymentMethods([]);
  };
};
