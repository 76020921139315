import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import HelpView from './help_view';

const HelpContainer = ({ currentSolutionHelp }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [showSolutionsList, setShowSolutionsList] = useState(false);
  const { push } = useHistory();
  const { operation } = useParams();

  useEffect(() => { setCurrentStep(0); }, [currentSolutionHelp]);

  if (!currentSolutionHelp?.steps?.length) {
    push(`/bv_cashier/${operation}`);
    return null;
  }

  return (
    <HelpView
      currentSolutionHelp={currentSolutionHelp}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      showSolutionsList={showSolutionsList}
      setShowSolutionsList={setShowSolutionsList}
    />
  );
};

HelpContainer.propTypes = {
  currentSolutionHelp: PropTypes.instanceOf(Object),
};

HelpContainer.defaultProps = {
  currentSolutionHelp: null,
};

export default HelpContainer;
