import PropTypes from 'prop-types';
import PaymentSolutionMessage from './payment_solution_message';

const SelectedPaymentMethod = ({ displayName }) => (
  <div className="asia-cashier__header">
    <p className="asia-cashier__header-name">{displayName}</p>
    <PaymentSolutionMessage />
  </div>
);

SelectedPaymentMethod.propTypes = {
  displayName: PropTypes.string.isRequired,
};

export default SelectedPaymentMethod;
