import { useEffect } from 'react';
import PropTypes from 'prop-types';
import PendingWithdrawsView from './pending_withdraws_view';

const PendingWithdrawsContainer = ({ withdraws, fetchPendingWithdraws, ...rest }) => {
  useEffect(() => {
    fetchPendingWithdraws();
  }, []);

  if (!withdraws.length) return null;
  return <PendingWithdrawsView withdraws={withdraws} {...rest} />;
};

PendingWithdrawsContainer.propTypes = {
  withdraws: PropTypes.instanceOf(Array).isRequired,
  fetchPendingWithdraws: PropTypes.func.isRequired,
};

export default PendingWithdrawsContainer;
