import PropTypes from 'prop-types';
import { Icon } from 'bv-components';

const WalletSelectView = ({ wallets, setSelectedWalletId, selectedWalletId }) => {
  const changeWallet = (event) => setSelectedWalletId(event.target.value);

  return wallets.length > 1 && (
    <div className="bvs-msg-box ac-wallet-selector-wrapper">
      <select onChange={changeWallet} value={selectedWalletId} className="ac-wallet-selector">
        {wallets.map(([key, value]) => (
          <option key={key} value={value}>
            {key}
          </option>
        ))}
      </select>
      <Icon id="arrow-right" />
    </div>
  );
};

WalletSelectView.propTypes = {
  wallets: PropTypes.instanceOf(Array).isRequired,
  setSelectedWalletId: PropTypes.func.isRequired,
  selectedWalletId: PropTypes.number.isRequired,
};

export default WalletSelectView;
