import PropTypes from 'prop-types';
import { Icon, Link } from 'bv-components';
import classnames from 'classnames';
import { t } from 'bv-i18n';
import { operationTypes, DEPOSIT, WITHDRAW } from 'CashierCommon/helpers';
import ToAccountMessage from './to_account_message';
import PendingAmount from './pending_amount';

const PendingWithdrawsView = (props) => {
  const { withdraws, amount, operation } = props;

  const divStyles = classnames({
    'bvs-msg-box': true,
    'is-left': true,
    'pending-withdraws': true,
    'pending-withdraws--empty': !withdraws.length && operation === DEPOSIT,
  });

  return (
    <>
      {operation === WITHDRAW && <ToAccountMessage />}
      <div className={divStyles}>
        <Link to="/bv_cashier/withdraw/pending" className="pending-withdraws__link">
          <span className="pending-withdraws__link-text">{t('pending_withdrawals')}</span>
          {operation === WITHDRAW && <PendingAmount amount={amount} />}
          <div className="pending-withdraws__container">
            <span className="pending-withdraws__counter">
              {withdraws.length}
            </span>
            <Icon id="arrow-right" />
          </div>
        </Link>
      </div>
    </>
  );
};

PendingWithdrawsView.propTypes = {
  amount: PropTypes.string.isRequired,
  withdraws: PropTypes.instanceOf(Array).isRequired,
  operation: PropTypes.oneOf(operationTypes).isRequired,
};

export default PendingWithdrawsView;
