import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getSession } from 'AsiaCashier/redux/selectors';
import AcceptMethodChangeContainer from './accept_method_change_container';

const mapStateToProps = createStructuredSelector({
  session: getSession,
});

export default connect(mapStateToProps)(AcceptMethodChangeContainer);
