import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { Modal } from 'bv-components';

const { CUSTOMER_CONSENT_URLS } = window.VCSTATE;

const AcceptMethodChangeView = ({ actions }) => (
  <Modal
    title={t('cashier.payments.activation_alert.title')}
    actions={actions}
    className="is-vertical"
    warning
  >
    <div className="modal-content">
      <p>{t('cashier.payments.activation_alert.first_phrase')}</p>
      <p>
        {t('cashier.payments.activation_alert.second_phrase')}
        <a href={CUSTOMER_CONSENT_URLS.terms_and_conditions}>
          {t('cashier.payments.activation_alert.more_info')}
        </a>
      </p>
      <p>{t('cashier.payments.activation_alert.third_phrase')}</p>
    </div>
  </Modal>
);

AcceptMethodChangeView.propTypes = {
  actions: PropTypes.arrayOf(Object).isRequired,
};

export default AcceptMethodChangeView;
