import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { setSelectedSolutionHelp } from 'AsiaCashier/redux/actions';
import { getCurrentSolutionHelpId, getSolutionsHelpList } from 'AsiaCashier/redux/selectors';
import HelpSolutionsListContainer from './help_solutions_list_container';

const mapStateToProps = createStructuredSelector({
  paymentSolutions: getSolutionsHelpList,
  currentSolutionHelpId: getCurrentSolutionHelpId,
  onCloseClick: (state, ownProps) => ownProps.onCloseClick,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedHelp: compose(dispatch, setSelectedSolutionHelp),
});

export default connect(mapStateToProps, mapDispatchToProps)(HelpSolutionsListContainer);
