export const SET_SETTINGS = 'asiaCashier/SET_SETTINGS';
export const SET_SELECTED_SOLUTION_HELP = 'asiaCashier/SET_SELECTED_SOLUTION_HELP';
export const SET_WALLET_LIST = 'asiaCashier/SET_WALLET_LIST';
export const SET_SELECTED_WALLET = 'asiaCashier/SET_SELECTED_WALLET';
export const SET_LOADING_SESSION = 'asiaCashier/SET_LOADING_SESSION';
export const SET_CASHIER_SESSION = 'asiaCashier/SET_CASHIER_SESSION';
export const SET_METHODS_LIST = 'asiaCashier/SET_METHODS_LIST';
export const SET_PENDING_WITHDRAWS = 'asiaCashier/SET_PENDING_WITHDRAWS';
export const SET_SELECTED_METHOD = 'asiaCashier/SET_SELECTED_METHOD';
export const SET_CURRENT_OPERATION = 'asiaCashier/SET_CURRENT_OPERATION';

export const setSelectedSolutionHelp = (solutionId) => ({
  type: SET_SELECTED_SOLUTION_HELP,
  solutionId,
});

export const setOperation = (operation) => ({
  type: SET_CURRENT_OPERATION,
  operation,
});

export const setPaymentMethods = (paymentMethods) => ({
  type: SET_METHODS_LIST,
  paymentMethods,
});

export const setSelectedPaymentMethod = (paymentMethod) => ({
  type: SET_SELECTED_METHOD,
  paymentMethod,
});

export const setCashierSession = (session) => ({
  type: SET_CASHIER_SESSION,
  session,
});

export const setLoadingSession = (loading) => ({
  type: SET_LOADING_SESSION,
  loading,
});

export const setPaymentSettings = (response) => ({
  type: SET_SETTINGS,
  response,
});

export const setWalletsList = (wallets) => ({
  type: SET_WALLET_LIST,
  wallets,
});

export const setSelectedWalletId = (id) => ({
  type: SET_SELECTED_WALLET,
  id,
});

export const setPendingWithdraws = (pendingWithdraws) => ({
  type: SET_PENDING_WITHDRAWS,
  pendingWithdraws,
});
