import { useEffect } from 'react';
import { isEmpty } from 'underscore';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { DEPOSIT, operationTypes } from 'CashierCommon/helpers';
import DepositView from './deposit_view';

const DepositContainer = ({
  setCurrentOperation,
  fetchDeposits,
  paymentMethods,
  currentOperation,
  selectedPaymentMethod,
  loading,
}) => {
  useEffect(() => {
    setCurrentOperation(DEPOSIT);
    fetchDeposits();
  }, []);

  if (isEmpty(paymentMethods) || currentOperation !== DEPOSIT) return <Spinner />;

  return <DepositView selectedPaymentMethod={selectedPaymentMethod} loading={loading} />;
};

DepositContainer.defaultProps = {
  currentOperation: null,
};

DepositContainer.propTypes = {
  fetchDeposits: PropTypes.func.isRequired,
  currentOperation: PropTypes.oneOf(operationTypes),
  setCurrentOperation: PropTypes.func.isRequired,
  paymentMethods: PropTypes.arrayOf(Object).isRequired,
  selectedPaymentMethod: PropTypes.instanceOf(Object).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default DepositContainer;
