import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { setLoadingSession } from 'AsiaCashier/redux/actions';
import { createPaymentSession, fetchPendingWithdraws } from 'AsiaCashier/redux/thunks';
import {
  getSession, getSelectedPaymentMethod, getWalletId, getCurrentOperation,
} from 'AsiaCashier/redux/selectors';
import CashierFormContainer from './cashier_form_container';

const mapStateToProps = createStructuredSelector({
  session: getSession,
  selectedPaymentMethod: getSelectedPaymentMethod,
  selectedWalletId: getWalletId,
  operation: getCurrentOperation,
});

const mapDispatchToProps = (dispatch) => ({
  createCashierSession: compose(dispatch, createPaymentSession),
  fetchPendingWithdraws: compose(dispatch, fetchPendingWithdraws),
  setLoadingPaymentMethod: compose(dispatch, setLoadingSession),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashierFormContainer);
