import PropTypes from 'prop-types';

import HelpSolutionsListView from './help_solutions_list_view';

const HelpSolutionsListContainer = ({
  setSelectedHelp, onCloseClick, paymentSolutions, currentSolutionHelpId,
}) => {
  const onSolutionClick = (solution) => {
    setSelectedHelp(solution.solutionId);
    onCloseClick();
  };

  return (
    <HelpSolutionsListView
      onSolutionClick={onSolutionClick}
      onCloseClick={onCloseClick}
      paymentSolutions={paymentSolutions}
      currentSolutionHelpId={currentSolutionHelpId}
    />
  );
};

HelpSolutionsListContainer.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  setSelectedHelp: PropTypes.func.isRequired,
  paymentSolutions: PropTypes.arrayOf(Object).isRequired,
  currentSolutionHelpId: PropTypes.number.isRequired,
};

export default HelpSolutionsListContainer;
