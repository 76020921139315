import PropTypes from 'prop-types';
import { toCurrency } from 'bv-helpers/number';

const PendingAmount = ({ amount }) => (
  <span className="pending-withdraws__amount">
    {toCurrency(amount)}
  </span>
);

PendingAmount.propTypes = {
  amount: PropTypes.number.isRequired,
};

export default PendingAmount;
