import { useEffect, useState } from 'react';
import { t } from 'bv-i18n';

const NUMBER_OF_STEPS = 3;

const Loading = () => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    let timeout = null;
    if (step < NUMBER_OF_STEPS) {
      timeout = setTimeout(() => setStep(step + 1), 2000);
    }
    return () => clearTimeout(timeout);
  }, [step]);

  return (
    <div className="skeleton is-loading asia-cashier-loading">
      <div className="asia-cashier-loading__skeleton-help">
        <div className="skeleton__header" />
      </div>
      <div className="skeleton__content">
        <div className="asia-cashier-loading__skeleton-small" />
      </div>
      <div className="skeleton__content">
        <div className="asia-cashier-loading__skeleton-large" />
      </div>
      <div className="asia-cashier-loading__icon-wrapper">
        <div className="asia-cashier-loading__icon" />
      </div>

      <div className="asia-cashier-loading__msg">{t(`javascript.cashier.loading.step_${step}`)}</div>
    </div>
  );
};

export default Loading;
