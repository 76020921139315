import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Spinner } from 'bv-components';
import { operationTypes, WITHDRAW } from 'CashierCommon/helpers';
import WithdrawView from './withdraw_view';

const WithdrawContainer = ({
  loading,
  paymentMethods,
  currentOperation,
  selectedPaymentMethod,
  fetchWithdraws,
  setCurrentOperation,
}) => {
  useEffect(() => {
    setCurrentOperation(WITHDRAW);
    fetchWithdraws();
  }, []);

  if (isEmpty(paymentMethods) || currentOperation !== WITHDRAW) return <Spinner />;

  return <WithdrawView selectedPaymentMethod={selectedPaymentMethod} loading={loading} />;
};

WithdrawContainer.defaultProps = {
  currentOperation: null,
};

WithdrawContainer.propTypes = {
  loading: PropTypes.bool.isRequired,
  paymentMethods: PropTypes.bool.isRequired,
  currentOperation: PropTypes.oneOf(operationTypes),
  selectedPaymentMethod: PropTypes.instanceOf(Object).isRequired,
  fetchWithdraws: PropTypes.func.isRequired,
  setCurrentOperation: PropTypes.func.isRequired,
};

export default WithdrawContainer;
