import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { getGenericMessage } from 'AsiaCashier/redux/selectors';

const WarningMessage = ({ genericMessage }) => {
  if (!genericMessage) return null;

  return (
    <div className="asia-cashier__warning">
      {genericMessage.message}
    </div>
  );
};

WarningMessage.propTypes = {
  genericMessage: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = createStructuredSelector({
  genericMessage: getGenericMessage,
});

export default connect(mapStateToProps)(WarningMessage);
