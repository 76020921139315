import PropTypes from 'prop-types';
import CashierMenuItem from '../cashier_menu_item';

const PaymentMethodsList = ({ methods, isRecommended }) => (
  methods.map(({ displayName, id, logoUrl }) => (
    <CashierMenuItem
      key={id}
      id={id}
      name={displayName}
      logoUrl={logoUrl}
      isRecommended={isRecommended}
    />
  ))
);

PaymentMethodsList.propTypes = {
  methods: PropTypes.arrayOf(Object).isRequired,
  isRecommended: PropTypes.bool,
};

PaymentMethodsList.defaultProps = {
  isRecommended: false,
};

export default PaymentMethodsList;
