import { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'underscore';
import { ModalWindow, Spinner } from 'bv-components';
import PropTypes from 'prop-types';
import { getSettingsFetched, getWalletsFetched } from 'AsiaCashier/redux/selectors';
import { fetchWallets, fetchSettings } from 'AsiaCashier/redux/thunks';

export default (WrappedComponent) => {
  const WithOperation = (props) => {
    const {
      doFetchWallets,
      fetchPaymentSettings,
      settingsFetched,
      walletsFetched,
    } = props;

    useEffect(() => {
      doFetchWallets();
      fetchPaymentSettings();
    }, []);

    if (!walletsFetched || !settingsFetched) {
      return (
        <ModalWindow>
          <Spinner />
        </ModalWindow>
      );
    }

    return <WrappedComponent {...props} />;
  };

  WithOperation.propTypes = {
    doFetchWallets: PropTypes.func.isRequired,
    fetchPaymentSettings: PropTypes.func.isRequired,
    settingsFetched: PropTypes.bool.isRequired,
    walletsFetched: PropTypes.bool.isRequired,
  };

  const mapStateToProps = createStructuredSelector({
    settingsFetched: getSettingsFetched,
    walletsFetched: getWalletsFetched,
  });

  const mapDispatchToProps = (dispatch) => ({
    doFetchWallets: compose(dispatch, fetchWallets),
    fetchPaymentSettings: compose(dispatch, fetchSettings),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithOperation);
};
