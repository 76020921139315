import { getJSON, postJSON } from 'bv-fetch';

const { CUSTOMER_CONSENT_SLUGS } = window.VCSTATE;

export const fetchDeposits = () => getJSON('/cashier/deposit/payment_methods.json');

export const fetchTermsPageJson = () => getJSON(`/pages/${CUSTOMER_CONSENT_SLUGS.terms_and_conditions}.json`);

export const postAcceptAgreement = () => postJSON(
  '/cashier/accept_insolvency_coverage',
  { accept_insolvency: true },
);

/* FE deposit methods */

export const createApplePaySession = (params) => (
  postJSON(
    '/cashier/apple_pay_session',
    params,
  )
);

export const processApplePay = (params) => (
  postJSON(
    '/cashier/apple_pay_process_payment',
    params,
  )
);

export const processGooglePay = (params) => (
  postJSON(
    '/cashier/google_pay_process_payment',
    params,
  )
);

export const fetchQuickAmounts = () => getJSON('/cashier/quick_amounts');
