import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';

const HelpCenterLink = () => (
  bvVar('helpCenterUrl') && (
    <span className="asia-cashier__help-center-link">
      <a href={bvVar('helpCenterUrl')} className="bvs-link bvs-text-brand" target="_blank" rel="noopener noreferrer">
        {t('cashier.online_help_center')}
      </a>
    </span>
  )
);

export default HelpCenterLink;
