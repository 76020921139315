import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getCurrentSolutionHelp } from 'AsiaCashier/redux/selectors';
import withCashierModal from 'CashierCommon/hocs/with_cashier_modal';
import HelpContainer from './help_container';

const mapStateToProps = createStructuredSelector({
  currentSolutionHelp: getCurrentSolutionHelp,
});

export default (operation) => connect(mapStateToProps)(
  withCashierModal({ tKey: `help_${operation}` })(HelpContainer),
);
