import PropTypes from 'prop-types';
import { withFeature } from 'bv-hocs';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';

const KycMessageView = ({ featureEnabled, session }) => {
  if (!featureEnabled || !session || session.isKycVerified !== false) return null;

  return (
    <div className="bvs-msg-box is-warning">
      <p className="bvs-icon is-warning" />
      <h3 className="bvs-msg-box__title">{t('id_verification.deposit_form_text')}</h3>
      <p>
        <a href={bvVar('kyc').helpPage}>
          {t('id_verification.deposit_form_link_text')}
        </a>
      </p>
    </div>
  );
};

KycMessageView.defaultProps = {
  session: null,
};

KycMessageView.propTypes = {
  featureEnabled: PropTypes.bool.isRequired,
  session: PropTypes.instanceOf(Object),
};

export default withFeature('check_kyc_before_withdraw')(KycMessageView);
