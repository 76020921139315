import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getAllWallets, getWalletId } from 'AsiaCashier/redux/selectors';
import { setSelectedWalletId } from 'AsiaCashier/redux/actions';
import WalletSelectView from './wallet_select_view';

const mapStateToProps = createStructuredSelector({
  wallets: getAllWallets,
  selectedWalletId: getWalletId,
});

export default connect(mapStateToProps, { setSelectedWalletId })(WalletSelectView);
