import { connect } from 'react-redux';
import { compose } from 'underscore';
import { createStructuredSelector } from 'reselect';
import { selectPendingAmount, selectPendingWithDraws, getCurrentOperation } from 'AsiaCashier/redux/selectors';
import { fetchPendingWithdraws } from 'AsiaCashier/redux/thunks';
import PendingWithDrawsContainer from './pending_withdraws_container';

const mapStateToProps = createStructuredSelector({
  amount: selectPendingAmount,
  withdraws: selectPendingWithDraws,
  operation: getCurrentOperation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPendingWithdraws: compose(dispatch, fetchPendingWithdraws),
});

export default connect(mapStateToProps, mapDispatchToProps)(PendingWithDrawsContainer);
