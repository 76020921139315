import { useEffect, useMemo, useRef } from 'react';
import { isGloballyEnabled } from 'bv-services/features';

export default (session, operation, setLoading) => {
  const form = useRef(null);

  const canLoadForm = useMemo(() => (operation === 'withdraw'
    ? !(isGloballyEnabled('check_kyc_before_withdraw') && !session?.isKycVerified)
    : true), [session]);

  useEffect(() => {
    if (session?.success) {
      if (canLoadForm && form.current) {
        form.current.submit();
      } else {
        setLoading(false);
      }
    }
  }, [session]);

  return { form, canLoadForm };
};
