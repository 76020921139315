import { useSelector } from 'react-redux';
import { v as bvVar } from 'bv';
import { t } from 'bv-i18n';
import { Link } from 'bv-components';
import { getCurrentOperation, getSolutionsHelpList } from 'AsiaCashier/redux/selectors';
import HelpCenterLink from './help_center_link';

const HowToGuide = () => {
  const operation = useSelector(getCurrentOperation);
  const solutions = useSelector(getSolutionsHelpList);

  if (!solutions.length && !bvVar('helpCenterUrl')) return null;

  return (
    <div className="asia-cashier__help">
      <HelpCenterLink />
      {solutions.length > 0 && (
        <Link to={`/bv_cashier/${operation}/help`} className="asia-cashier__help-btn">
          {t(`how_to_${operation}_guide`)}
          <span className="asia-cashier__help-icon" />
        </Link>
      )}
    </div>
  );
};

export default HowToGuide;
