import classNames from 'classnames';
import PropTypes from 'prop-types';
import CashierForm from '../cashier_form/cashier_form_redux';
import CashierMenu from '../cashier_menu/cashier_menu_redux';
import HowToGuide from '../how_to_guide';
import PendingWithdraws from '../pending_withdraw/pending_withdraws_redux';
import WarningMessage from '../warning_message';
import KycMessage from '../kyc_message/kyc_messaged_redux';
import WalletSelect from '../wallet_select/wallet_select_redux';
import Loading from '../loading';
import SelectedPaymentMethod from '../selected_payment_method';

const WithdrawView = ({ loading, selectedPaymentMethod }) => (
  <div className={classNames('asia-cashier withdraw-form', { 'is-loading': loading })}>
    {loading && <Loading />}
    <HowToGuide />
    <PendingWithdraws />
    <WarningMessage />
    <CashierMenu />
    <KycMessage />
    <WalletSelect />
    {selectedPaymentMethod && <SelectedPaymentMethod {...selectedPaymentMethod} />}
    <CashierForm />
  </div>
);

WithdrawView.propTypes = {
  loading: PropTypes.bool.isRequired,
  selectedPaymentMethod: PropTypes.instanceOf(Object).isRequired,
};

export default WithdrawView;
