import { getJSON, putJSON } from 'bv-fetch';
import { locale } from 'bv-i18n';

export const fetchPaymentSettings = () => getJSON('/api/payment_settings');

export const fetchAvailableWallets = () => (
  getJSON('/cashier/wallets.json')
);

export const createCashierSession = (params) => (
  putJSON(
    `/bv_cashier/${locale()}/update_wallet.json`,
    params,
  )
);
